import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { translatePath, uiHandleError, uiHandleSuccess } from "../../../utils"
import useCommonQueries from "../../../hooks/useCommonQueries"
import { useAuth0 } from "@auth0/auth0-react"
import PageRoot from "../../../components/PageRoot"
import UserBasicProfileForm from "../../../components/forms/UserBasicProfileForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { Skeleton, Spin } from "antd"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"

const INSERT_USER_PROFILE_MUTATION = gql`
  mutation insertUserProfile($data: user_profiles_insert_input!) {
    insert_user_profiles_one(object: $data) {
      id
    }
  }
`

const CreateProfilePage = () => {
  const { t } = useTranslation()
  const { getCurrentUser } = useCommonQueries()
  const [creatingProfile, setCreatingProfile] = useState(false)
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [insertUserProfile] = useMutation(INSERT_USER_PROFILE_MUTATION)

  const checkBasicProfile = async () => {
    try {
      if (isAuthenticated) {
        const userData = await getCurrentUser()
        if (userData.basic_profile) {
          navigate(translatePath("/me/profile"))
        }
      }
    } catch (error) {
      uiHandleError({ error })
    }
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    checkBasicProfile()
  }, [isLoading])

  const handleFormSubmit = async values => {
    setCreatingProfile(true)
    try {
      const { privacy, tos, ...data } = values
      await insertUserProfile({ variables: { data } })
      uiHandleSuccess({
        message: t("message:profileCreated"),
        action: () => {
          navigate("/", { replace: true })
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setCreatingProfile(false)
  }

  if (isLoading) {
    return <Skeleton active round />
  }

  return (
    <PageRoot>
      <ContainerWithPadding size={"small"}>
        <div className="wrapper-generico">
          <h1 className="header-profilazione">
            {t("message:profileRequiredMessage")}:
          </h1>
          <Spin spinning={creatingProfile}>
            <UserBasicProfileForm
              onFinish={handleFormSubmit}
              formType={FORM_TYPE_ADD}
            />
          </Spin>
        </div>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default CreateProfilePage
