import React from "react"
import { Button, Checkbox, Form, Input } from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import { useTranslation } from "react-i18next"
import useTosAndPrivacyFormItems from "./useTosAndPrivacyFormItems"
import PreferenceTagSelect from "../customFormItems/PreferenceTagSelect"

const UserBasicProfileForm = ({ initialValues, onFinish, formType }) => {
  const { t } = useTranslation()
  const tosAndPrivacyFormItems = useTosAndPrivacyFormItems()

  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={"vertical"}>
      <Form.Item
        label={t("form:label.firstname")}
        name="firstname"
        rules={[
          {
            required: true,
            message: t("form:error.firstnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.firstname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.lastname")}
        name="lastname"
        rules={[
          {
            required: true,
            message: t("form:error.lastnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.lastname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.email")}
        name="email"
        rules={[
          {
            required: true,
            message: t("form:error.emailRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.email")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.phoneNumber")}
        name="phone_number"
        rules={[
          {
            required: true,
            message: t("form:error.phoneNumberRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.phoneNumber")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.preferenceTag")}
        name="preferenceTags"
        rules={[
          { required: true, message: t("form:error.preferenceTagsRequired") },
          {
            min: 3,
            type: "array",
            message: t("form:error.selectAtLeastXTags", { count: 3 }),
          },
          {
            max: 7,
            type: "array",
            message: t("form:error.selectAtMaxXTags", { count: 7 }),
          },
        ]}
      >
        <PreferenceTagSelect />
      </Form.Item>

      <p>{t("form:message.newsletterMessage1")}:</p>

      <Form.Item name="newsletter" valuePropName="checked" noStyle>
        <Checkbox>{t("form:message.newsletterMessage2")}</Checkbox>
      </Form.Item>

      <div className="form-submit-button-wrapper">
        <Form.Item>
          <Button
            size="large"
            className="green"
            type="primary"
            htmlType="submit"
          >
            {formType === FORM_TYPE_ADD
              ? t("button:completeSubscription")
              : t("button:update")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default UserBasicProfileForm
