import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Space, Tag } from "antd"
import "./PlanSelector.less"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "../LoadingScreen"
import ErrorScreen from "../ErrorScreen"
import "./PreferenceTagSelect.less"

const GET_ALL_CATEGORIES_QUERY = gql`
  {
    categories(order_by: { name: asc }) {
      name
    }
  }
`

const PlanSelector = ({ value, onChange }) => {
  const [selectedTags, setSelectedTags] = useState(value || [])
  const { t } = useTranslation()

  const { loading, error, data, refetch } = useQuery(GET_ALL_CATEGORIES_QUERY)

  const handleTagSelect = tag => {
    let newTags = [...selectedTags]
    if (newTags.includes(tag)) {
      newTags.splice(newTags.indexOf(tag), 1)
    } else {
      newTags.push(tag)
    }
    onChange && onChange(newTags)
    setSelectedTags(newTags)
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <div className="preference-tag-selector">
      <Space wrap>
        {data.categories.map((category, index) => (
          <Tag
            className={
              selectedTags.includes(category.name)
                ? "preference-tag active"
                : "preference-tag"
            }
            key={index}
            onClick={() => {
              handleTagSelect(category.name)
            }}
          >
            {category.name}
          </Tag>
        ))}
      </Space>
    </div>
  )
}

export default PlanSelector
